// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ars-vr-js": () => import("./../../../src/pages/ars-vr.js" /* webpackChunkName: "component---src-pages-ars-vr-js" */),
  "component---src-pages-beammeup-index-js": () => import("./../../../src/pages/beammeup/index.js" /* webpackChunkName: "component---src-pages-beammeup-index-js" */),
  "component---src-pages-browse-index-js": () => import("./../../../src/pages/browse/index.js" /* webpackChunkName: "component---src-pages-browse-index-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-partner-admin-approval-js": () => import("./../../../src/pages/partner/admin/approval.js" /* webpackChunkName: "component---src-pages-partner-admin-approval-js" */),
  "component---src-pages-partner-admin-feature-js": () => import("./../../../src/pages/partner/admin/feature.js" /* webpackChunkName: "component---src-pages-partner-admin-feature-js" */),
  "component---src-pages-partner-admin-index-js": () => import("./../../../src/pages/partner/admin/index.js" /* webpackChunkName: "component---src-pages-partner-admin-index-js" */),
  "component---src-pages-partner-admin-migrate-js": () => import("./../../../src/pages/partner/admin/migrate.js" /* webpackChunkName: "component---src-pages-partner-admin-migrate-js" */),
  "component---src-pages-partner-admin-rooms-js": () => import("./../../../src/pages/partner/admin/rooms.js" /* webpackChunkName: "component---src-pages-partner-admin-rooms-js" */),
  "component---src-pages-partner-admin-tag-projects-index-js": () => import("./../../../src/pages/partner/admin/tag-projects/index.js" /* webpackChunkName: "component---src-pages-partner-admin-tag-projects-index-js" */),
  "component---src-pages-partner-admin-tags-index-js": () => import("./../../../src/pages/partner/admin/tags/index.js" /* webpackChunkName: "component---src-pages-partner-admin-tags-index-js" */),
  "component---src-pages-partner-dashboard-js": () => import("./../../../src/pages/partner/dashboard.js" /* webpackChunkName: "component---src-pages-partner-dashboard-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-partner-profile-index-js": () => import("./../../../src/pages/partner/profile/index.js" /* webpackChunkName: "component---src-pages-partner-profile-index-js" */),
  "component---src-pages-partner-submit-index-js": () => import("./../../../src/pages/partner/submit/index.js" /* webpackChunkName: "component---src-pages-partner-submit-index-js" */),
  "component---src-pages-tv-stations-js": () => import("./../../../src/pages/tv-stations.js" /* webpackChunkName: "component---src-pages-tv-stations-js" */),
  "component---src-pages-vr-experiences-js": () => import("./../../../src/pages/vr-experiences.js" /* webpackChunkName: "component---src-pages-vr-experiences-js" */),
  "component---src-templates-main-tag-js": () => import("./../../../src/templates/mainTag.js" /* webpackChunkName: "component---src-templates-main-tag-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

