import React, { useRef, useContext, useEffect, useState } from "react"
import ContextConsumer from "../components/context/Context"
import { navigate } from "gatsby"
import "./LeafletMap.css"
import BackButton from "../images/backButton.png"
import Abflughalle from "../images/marker/abflughalle.png"
import ARS from "../images/marker/ars.png"
import Featured from "../images/marker/featured.png"
import NotOnHubs from "../images/marker/notOnHubs.png"
import OnHubs from "../images/marker/onHubs.png"

import {
  Map,
  TileLayer,
  Marker,
  Popup,
  LayerGroup,
  useLeaflet,
} from "react-leaflet"
import L from "leaflet"
import "../components/AddMarkerButton"
import AddMarkerButton from "../components/AddMarkerButton"

const LeafletMap = props => {
  const mapRef = useRef()
  const { current = {} } = mapRef
  const { leafletElement: map } = current
  const [draggableMarker, setDraggableMarker] = useState([])

  useEffect(() => {
    // console.log("fly to props: ", props.flyTo)
    if (props.flyTo.length > 0) {
      // console.log("should trigger...")
      handleFlyTo()
    }
    if (props.flyToBounds.length > 0) {
      handleFlyToBounds()
    }
  })
  function handleSetTo() {
    // console.log("set to triggered")
  }

  async function handleFlyTo() {
    // await map.flyTo(props.flyTo, props.flyToZoom)
    await map.panTo(props.flyTo)
  }
  function handleFlyToBounds() {
    // console.log("fly to bounds triggered")
    map.flyToBounds(props.flyToBounds)
  }

  function updateDraggableMarker(event) {
    const latLng = event.target.getLatLng() //get marker LatLng
    // console.log("new drag: ", latLng)
    setDraggableMarker([latLng.lat, latLng.lng])
    props.updateDraggableMarker(latLng)
  }
  // Center is Linz
  const mapState = {
    lat: 48.309736,
    lng: 14.284343,
    zoom: 3,
  }
  const position = [mapState.lat, mapState.lng]
  // const { point } = useContext(LayerContext)
  // console.log("passed marker in leaflet: ", props.marker)
  // console.log("should display a marker: ", props.shouldDisplayMarker)

  if (typeof window !== "undefined") {
    // MARKER-ICON
    const abflughalleMarkerIcon = new L.Icon({
      iconUrl: Abflughalle,
      iconRetinaUrl: Abflughalle,
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
      shadowUrl: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [10, 10],
      iconSize: new L.Point(30, 30),
      className: "leaflet-div-icon",
    })
    const arsMarkerIcon = new L.Icon({
      iconUrl: ARS,
      iconRetinaUrl: ARS,
      iconAnchor: [30, 30],
      popupAnchor: [-15, -30],
      shadowUrl: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [10, 10],
      iconSize: new L.Point(30, 30),
      className: "leaflet-div-icon",
    })
    const featuredMarkerIcon = new L.Icon({
      iconUrl: Featured,
      iconRetinaUrl: Featured,
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
      shadowUrl: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [10, 10],
      iconSize: new L.Point(30, 30),
      className: "leaflet-div-icon",
    })
    const notOnHubsMarkerIcon = new L.Icon({
      iconUrl: NotOnHubs,
      iconRetinaUrl: NotOnHubs,
      iconAnchor: [15, 15],
      popupAnchor: [0, -30],
      shadowUrl: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [10, 10],
      iconSize: new L.Point(30, 30),
      className: "leaflet-div-icon",
    })
    const onHubsMarkerIcon = new L.Icon({
      iconUrl: OnHubs,
      iconRetinaUrl: OnHubs,
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
      shadowUrl: [10, 10],
      shadowSize: [0, 0],
      shadowAnchor: [10, 10],
      iconSize: new L.Point(30, 30),
      className: "leaflet-div-icon",
    })

    return (
      <div>
        {/* <ContextHack draggableMarker={draggableMarker} /> */}
        <Map
          key="map"
          ref={mapRef}
          center={position}
          zoom={mapState.zoom}
          zoomControl={false}
          // minZoom="1"
          minZoom="3"
          // maxZoom="10"
          maxZoom="3"
          zoomAnimation={false}
          id="mapId"
        >
          {props.shouldDisplayMarker &&
            props.marker.map(marker => {
              // Abflughalle
              if (marker.id === "d2a3e521-5d03-42fd-b0fb-dd5329d7b0ef")
                return (
                  <Marker
                    icon={abflughalleMarkerIcon}
                    key={marker.position}
                    position={marker.position}
                    onClick={() =>
                      marker.id !== undefined &&
                      navigate("/project/" + marker.id)
                    }
                    zIndexOffset="1000"
                  >
                    <Popup>{marker.text}</Popup>
                  </Marker>
                )
              // ARS Projekte
              else if (marker.text.includes("Ars Electronica Linz"))
                return (
                  <Marker
                    icon={arsMarkerIcon}
                    key={marker.position}
                    position={marker.position}
                    onClick={() =>
                      marker.id !== undefined &&
                      navigate("/project/" + marker.id)
                    }
                  >
                    <Popup>{marker.text}</Popup>
                  </Marker>
                )
              // Mit Hubs
              else if (marker.onHubs)
                return (
                  <Marker
                    icon={onHubsMarkerIcon}
                    key={marker.position}
                    position={marker.position}
                    onClick={() =>
                      marker.id !== undefined &&
                      navigate("/project/" + marker.id)
                    }
                  >
                    <Popup>{marker.text}</Popup>
                  </Marker>
                )
              // Featured
              else if (marker.featured)
                return (
                  <Marker
                    icon={featuredMarkerIcon}
                    key={marker.position}
                    position={marker.position}
                    onClick={() =>
                      marker.id !== undefined &&
                      navigate("/project/" + marker.id)
                    }
                  >
                    <Popup>{marker.text}</Popup>
                  </Marker>
                )
              else {
                return (
                  <Marker
                    icon={notOnHubsMarkerIcon}
                    key={marker.position}
                    position={marker.position}
                    onClick={() =>
                      marker.id !== undefined &&
                      navigate("/project/" + marker.id)
                    }
                  >
                    <Popup>{marker.text}</Popup>
                  </Marker>
                )
              }
            })}
          {props.draggableMarkerHasBeenSet && props.draggableMarker.length > 0 && (
            <ContextConsumer>
              {({ data, set }) => {
                return (
                  <Marker
                    key={props.draggableMarker}
                    position={props.draggableMarker}
                    draggable={true}
                    ondragend={event => {
                      const latLng = event.target.getLatLng()
                      set({ draggableMarker: [latLng.lat, latLng.lng] })
                    }}
                  ></Marker>
                )
              }}
            </ContextConsumer>
          )}
          {/* <AddMarkerButton /> */}
          {/* <LayerGroup>{point}</LayerGroup> */}

          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://api.maptiler.com/maps/0be2a7a5-5c82-43a9-874c-6a4e15244bfe/{z}/{x}/{y}.png?key=LmgkrTIKxy4OmJ9DDUbA"
            // tileSize="512"
            // zoomOffset=" -1"
            // minZoom="1"
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
            // crossOrigin="true"
          />
        </Map>
      </div>
    )
  }
  return null
}
export default LeafletMap
