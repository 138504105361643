/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import Amplify from "aws-amplify"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import LeafletMap from "./LeafletMap"
import Header from "./header"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"
import "../style/index.scss"
import "../components/context/LayerContext"
import { LayerContextProvider } from "../components/context/LayerContext"
import { LeafletConsumer } from "react-leaflet"
import { useLeaflet, Marker, Popup } from "react-leaflet"
import { ContextProviderComponent } from "../components/context/Context"
import awsExports from "../aws-exports"
import Div100vh from "react-div-100vh"
Amplify.configure(awsExports)

const Layout = props => {
  const [marker, setMarker] = useState([{}])
  const [draggableMarker, setDraggableMarker] = useState([])
  const [draggableMarkerHasBeenSet, setDraggableMarkerHasBeenSet] = useState(
    false
  )
  const [updatedDraggableMarker, setUpdatedDraggableMarker] = useState([])

  const [flyTo, setFlyTo] = useState([])
  const [flyToZoom, setFlyToZoom] = useState(8)
  const [flyToBounds, setFlyToBounds] = useState([])

  const [markerHasBeenSet, setMarkerHasBeenSet] = useState(false)
  const mapState = {
    lat: 51.505,
    lng: -0.09,
    zoom: 3,
  }

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      addMarker: function addMarker(marker) {
        setMarker(marker)
        if (marker.length === 0) {
          setMarkerHasBeenSet(false)
        } else {
          setMarkerHasBeenSet(true)
        }
      },
      flyTo: function handleFlyToState(position) {
        setFlyTo(position)
      },
      flyToZoom: function handleFlyToZoomState(zoom) {
        setFlyToZoom(zoom)
      },
      flyToBounds: function handleFlyToBoundsState(bounds) {
        setFlyToBounds(bounds)
      },
      addDraggableMarker: function handleSetDraggableMarker(position) {
        // if (position.length > 0) {
        setDraggableMarker(position)
        setDraggableMarkerHasBeenSet(true)
        // }
      },
      // updatedDraggableMarker: updatedDraggableMarker,
    })
  )

  const passedMarker = markerHasBeenSet ? marker : []

  function handleDraggableMarkerUpdate(latLng) {
    setUpdatedDraggableMarker([latLng.lat, latLng.lng])
  }
  // console.log("passed marker: ", marker)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const changedContextValue = {
    data: {
      // set your initial data shape here
      menuOpen: true,
      // draggableMarker: [],
    },
  }

  return (
    <>
      <ContextProviderComponent value={{ changedContextValue }}>
        <Div100vh>
          <Header />
          <div>
            <LeafletMap
              draggableMarkerHasBeenSet={draggableMarkerHasBeenSet}
              draggableMarker={draggableMarker}
              flyTo={flyTo}
              flyToZoom={flyToZoom}
              flyToBounds={flyToBounds}
              mapState={mapState}
              marker={passedMarker}
              shouldDisplayMarker={markerHasBeenSet}
              updateDraggableMarker={function updateDraggableMarker(latLng) {
                handleDraggableMarkerUpdate(latLng)
                // console.log("yepp... drag: ", updatedDraggableMarker)
              }}
            />
            <main>{childrenWithProps}</main>
            <footer>
            </footer>
          </div>
        </Div100vh>
      </ContextProviderComponent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
