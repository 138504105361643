import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.scss"
import Logo from "../images/Logo_Ars Electronica Festival_black.png"

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-flex">
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img style={{ maxHeight: "40px" }} src={Logo}></img>
        </Link>
      </h1>
      <div className="imprint">
        <Link to="/about">About</Link>
        {/* <a
          href="https://partner.keplersgardens.info/impressum-2/"
          target="_blank"
        >
          Imprint
        </a> */}
        <Link to="/imprint">Imprint</Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
